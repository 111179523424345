import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetTextField from '../WidgetTextField';

const Photo = memo(({ data, photoPreview }) => {

    const photos = data ?? [];
    const preview = photoPreview ?? [];

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = ({ photo, index }) => {

        const Success = () => {
            return (
                < Avatar sx={{ height: 20, width: 20, bgcolor: "success.main", mr: 1 }}>
                    <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />
                </Avatar >
            );
        };

        const Error = () => {
            return (
                <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", mr: 1 }}>
                    <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
                </Avatar>
            );
        };

        return (
            <Paper
                elevation={2}
                sx={{
                    width: "100%",
                    pb: 1,
                    mb: 1.5,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        p: 1,
                        borderBottom: 1,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            pr: 2
                        }}
                    >
                        {photo?.filename}
                    </Typography>
                </Grid>
                <Box sx={{ py: 1, pb: 0 }}>
                    <Grid container sx={{ justifyContent: belowsm ? "center" : "flex-start" }}>
                        <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "center", pl: 1, pr: belowsm ? 1 : 0 }}>
                            <img alt={`preview-${index}`} src={preview[index]} style={{ borderBottomLeftRadius: "12px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "100%" }} />
                        </Grid>
                        <Grid item xs={12} sm={9} zeroMinWidth sx={{ pl: belowsm ? 0 : 2 }}>
                            <Box sx={{ mt: belowsm ? 2 : 0 }}>
                                <WidgetTextField
                                    color="widget"
                                    value={photo?.related ? t("photoMatches") : t("photoDoesNotMatch")}
                                    variant="standard"
                                    fullWidth
                                    focused
                                    multiline
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                        startAdornment: photo?.related ? <Success /> : <Error />,
                                        sx: {
                                            px: belowsm ? 1 : 0,
                                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                        }
                                    }}
                                />
                                <WidgetTextField
                                    color="widget"
                                    label={t("description")}
                                    value={photo?.description ?? ""}
                                    variant="standard"
                                    fullWidth
                                    focused
                                    multiline
                                    InputLabelProps={{
                                        sx: { px: belowsm ? 1 : 0 }
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        sx: {
                                            px: belowsm ? 1 : 0,
                                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                                            borderTopRightRadius: "12px",
                                            borderTopLeftRadius: "12px"
                                        }
                                    }}
                                    sx={{ mt: 1 }}
                                />
                                <WidgetTextField
                                    color="widget"
                                    label={t("explanation")}
                                    value={checkInput(photo?.relatedReasoning)}
                                    variant="standard"
                                    fullWidth
                                    focused
                                    multiline
                                    InputLabelProps={{
                                        sx: { px: belowsm ? 1 : 0 }
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                        sx: {
                                            px: belowsm ? 1 : 0,
                                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                                            borderTopRightRadius: "12px",
                                            borderTopLeftRadius: "12px"
                                        }
                                    }}
                                    sx={{ mt: 1 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box >
            </Paper>
        );
    };

    if (photos.length === 0) {
        return (
            <Box sx={{ height: belowsm ? "calc(100vh - 277px)" : "auto", width: belowsm ? "90vw" : "80vw", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center", mt: belowsm ? 0 : "35vh" }}>
                <Typography color={theme.palette.text.primary}>{t("photoDisplayError")}</Typography>
            </Box>
        )
    }

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 354px)" : "auto", overflow: "auto", justifyContent: "center", alignItems: belowsm ? "flex-start" : "center" }}>
            {photos?.map((p, i) => { return <Preview key={i} photo={p} index={i} /> })}
        </Grid>
    );
}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

export default Photo;