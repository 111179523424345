import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Collapse, Divider, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoIcon from '../icons/Logo';
import UserDialog from '../components/Dialog/UserDialog';
import { ClaimContext } from '../utils/ClaimContext';
import { useTranslation } from 'react-i18next';

const Nav = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { user, isAuthenticated, signoutRedirect } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const { setClaim } = useContext(ClaimContext);

    const [collapse, setCollapse] = useState(false);
    const [userCollapse, setUserCollapse] = useState(false);

    const profileRef = useRef(null);

    useEffect(() => {
        if (!belowsm) {
            setCollapse(false);
            setUserCollapse(false);
        }
        if (location.pathname !== "/complete") {
            setClaim({});
        };
        document.body.style.backgroundColor = theme.palette.background.default;
        sessionStorage.removeItem("redirect");

    }, [belowsm, theme.palette.background.default, location.pathname, setClaim]);

    const handleClick = () => {
        setCollapse(!collapse);
        setUserCollapse(false);
    };

    const handleMobileUserClick = () => {
        setUserCollapse(!userCollapse);
        setCollapse(false);
    };

    const handleLogout = () => {
        setUserCollapse(false);
        localStorage.removeItem("claim");
        signoutRedirect();
    };

    const handlePageChange = (path) => {
        navigate(path);
        setCollapse(!collapse);
    };

    const handleProfileClick = () => profileRef.current.open(user?.profile?.email ?? "");

    return (
        <AppBar position="sticky" sx={{ boxShadow: collapse || userCollapse ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", bgcolor: theme.palette.mode === "dark" ? "primary.dark" : "primary.main", display: isAuthenticated ? "flex" : "none" }}>
            <Toolbar>
                <Grid container flexDirection="column">
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
                        <IconButton
                            onClick={handleClick}
                            size="large"
                            edge="start"
                            color="inherit"
                            sx={{ mr: belowsm ? 0 : 2, my: 1, display: belowsm ? "inline-flex" : "none" }}
                        >
                            {collapse ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box sx={{ mt: belowsm ? 0 : .5, flexGrow: belowsm ? 1 : 0, display: "flex", justifyContent: "center", alignItems: "center", height: "64px" }}>
                            <LogoIcon />
                            <Typography variant="h6" fontWeight={700} component="div" noWrap color={"inherit"} sx={{ ml: 2, display: belowsm ? "none" : "flex" }}>
                                Swift Robin
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: belowsm ? "none" : "flex" }} />
                        <Box sx={{ display: belowmd ? "none" : "flex", justifyContent: "center", mr: 2 }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("report")}</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("complete")}</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="text" color="inherit" disableRipple sx={{ my: 2, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("myClaims")}</Button>
                        </Box>
                        <Button size="medium" onClick={handleProfileClick} variant="contained" color="profile" disableElevation sx={{ my: 2, textTransform: "none", color: "profile.contrastText", borderRadius: "8px", border: 1, borderColor: "profile.main", display: belowsm ? "none" : "inline-flex", width: "100px", mr: 1 }}>{t("profile")}</Button>
                        <Button size="medium" onClick={handleLogout} variant="contained" color="signout" disableElevation sx={{ my: 2, textTransform: "none", color: "signout.contrastText", borderRadius: "8px", border: 1, borderColor: "signout.border", display: belowsm ? "none" : "inline-flex", width: "100px" }}>{t("signout")}</Button>
                        <IconButton
                            onClick={handleMobileUserClick}
                            size="large"
                            edge="end"
                            color="inherit"
                            sx={{ my: 1, display: belowsm ? "inline-flex" : "none" }}
                        >
                            <PersonIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sx={{ display: belowmd && !belowsm ? "block" : "none" }}>
                        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", borderTop: 1, borderColor: "inherit", pt: 1 }}>
                            <Button size="large" onClick={() => { navigate("/report") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/report" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("report")}</Button>
                            <Button size="large" onClick={() => { navigate("/complete") }} variant="text" color="inherit" disableRipple sx={{ mr: 2, mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/complete" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("complete")}</Button>
                            <Button size="large" onClick={() => { navigate("/claims") }} variant="text" color="inherit" disableRipple sx={{ mb: 1, textTransform: "none", borderRadius: "8px", textDecoration: location.pathname === "/claims" ? "underline" : "none", "&:hover": { textDecoration: "underline" } }}>{t("myClaims")}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={collapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem onClick={() => { handlePageChange("/report"); }}>
                            <ListItemText inset>{t("report")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/complete"); }}>
                            <ListItemText inset>{t("complete")}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handlePageChange("/claims"); }}>
                            <ListItemText inset>{t("myClaims")}</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
            <Collapse in={userCollapse} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Paper elevation={0} sx={{ backgroundImage: "none", borderRadius: 0 }}>
                    <MenuList dense>
                        <MenuItem>
                            <ListItemIcon>
                                <HowToRegIcon sx={{ color: theme.palette.text.primary }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography fontSize="inherit" noWrap>{t("loggedInWith", { email: user?.profile?.email ?? "" })}</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon sx={{ color: theme.palette.text.primary }} />
                            </ListItemIcon>
                            <ListItemText>{t("signout")}</ListItemText>
                        </MenuItem>
                    </MenuList>
                    <Divider />
                </Paper>
            </Collapse>
            <UserDialog ref={profileRef} />
        </AppBar>
    );
});

export default Nav;