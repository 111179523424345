import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from 'lodash';
import { Fragment, forwardRef, memo, useContext, useImperativeHandle, useRef, useState } from "react";
import { ClaimContext } from '../../utils/ClaimContext';
import { useAxios } from "../../utils/useAxios";
import Attachment from "./Attachment/Attachment";
import FnolAttachment from './Attachment/FnolAttachment';
import Message from "./Attachment/Message";
import Photo from "./Attachment/Photo";
import PoliceReportAttachment from './Attachment/PoliceReportAttachment';
import Completeness from './Completeness';
import Coverage from './Coverage/Coverage';
import Invoice from './Invoice/Invoice';
import Overview from "./Overview/Overview";
import { useTranslation } from 'react-i18next';

const ViewHandler = memo(forwardRef((props, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [photo, setPhoto] = useState();
    const [attachment, setAttachment] = useState();
    const [photoPreview, setPhotoPreview] = useState([]);
    const [attachmentPreview, setAttachmentPreview] = useState([]);
    const [source, setSource] = useState([]);
    const [displayOverview, setDisplayOverview] = useState(true);
    const [displayMessage, setDisplayMessage] = useState(false);
    const [displayPhoto, setDisplayPhoto] = useState(false);
    const [displayAttachment, setDisplayAttachment] = useState(false);
    const [displayFnol, setDisplayFnol] = useState(false);
    const [displayPoliceReport, setDisplayPoliceReport] = useState(false);
    const [displaySource, setDisplaySource] = useState(false);
    const [displayCoverage, setDisplayCoverage] = useState(false);
    const [displayCompleteness, setDisplayCompleteness] = useState(false);
    const [displayInvoice, setDisplayInvoice] = useState(false);
    const [loading, setLoading] = useState(false);

    const viewRef = useRef(null);

    const { claim } = useContext(ClaimContext);

    const { t } = useTranslation();

    const axiosInstance = useAxios();

    //eslint-disable-next-line
    const fetchPdf = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/pdf/${claim?.claimUUID}`,
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pdf-report-${claim?.claimUUID}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const fetchSource = async (data) => {
        setLoading(true);

        await axiosInstance.current({
            url: data?.path || "",
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: data?.contentType || "text/plain" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data?.filename || "source-document.txt"}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const downloadAllSource = async () => {
        for (let i = 0; i < source?.length; i++) {
            await fetchSource(source[i]);
        };
    };

    useImperativeHandle(ref, () => ({
        openOverview() {
            viewRef.current.scrollTo(0, 0);
            setDisplayOverview(true);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        openCompleteness() {
            viewRef.current.scrollTo(0, 0);
            setDisplayCompleteness(true);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayInvoice(false);
        },
        openCoverage() {
            viewRef.current.scrollTo(0, 0);
            setDisplayCoverage(true);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        openInvoice() {
            viewRef.current.scrollTo(0, 0);
            setDisplayInvoice(true);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCompleteness(false);
        },
        handleMessage() {
            viewRef.current.scrollTo(0, 0);
            setDisplayMessage(true);
            setDisplayOverview(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handlePhoto(data, preview, sourceArray) {
            viewRef.current.scrollTo(0, 0);
            setPhoto(data);
            setPhotoPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplayPhoto(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handleAttachment(data, preview, sourceArray) {
            viewRef.current.scrollTo(0, 0);
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handleFnol(data, preview, sourceArray) {
            viewRef.current.scrollTo(0, 0);
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray)
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayPoliceReport(false);
            setDisplayFnol(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handlePoliceReport(data, preview, sourceArray) {
            viewRef.current.scrollTo(0, 0);
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        }
    }));

    const closeView = () => {
        setDisplayOverview(false);
        setDisplayMessage(false);
        setDisplayPhoto(false);
        setDisplayAttachment(false);
        setDisplayFnol(false);
        setDisplayPoliceReport(false);
        setDisplaySource(false);
        setDisplayCoverage(false);
        setDisplayCompleteness(false);
        setDisplayInvoice(false);
        if (belowsm) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleMobileStageView = () => {
        if (claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED") {
            return false;
        } else {
            return true;
        }
    };

    const LoadingView = () => {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    const NoView = () => {
        return (
            <Box sx={{ height: "100%", width: belowsm ? "90vw" : "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography noWrap color={theme.palette.text.primary}>Keine Ansicht ausgewählt.</Typography>
            </Box>
        );
    };

    const StageActionButtons = memo(({ component }) => {
        return (
            <Box sx={{ height: belowsm ? displaySource ? handleMobileStageView() ? "calc(100vh - 416px)" : "calc(100vh - 354px)" : handleMobileStageView() ? "calc(100vh - 366px)" : "calc(100vh - 304px)" : belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)" }}>
                {component}
            </Box>
        );
    }, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

    return (
        <Fragment>
            <Box ref={viewRef} sx={{ height: belowsm ? displaySource ? handleMobileStageView() ? "calc(100vh - 416px)" : "calc(100vh - 354px)" : handleMobileStageView() ? "calc(100vh - 366px)" : "calc(100vh - 304px)" : belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)", overflow: "auto", mt: belowsm ? handleMobileStageView() ? 10 : 2 : 0, borderRadius: "14.362px" }}>
                {
                    loading ?
                        <LoadingView /> :
                        displayOverview ?
                            claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                <Overview /> :
                                <StageActionButtons component={<Overview />} /> :
                            displayCompleteness ?
                                claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                    <Completeness /> :
                                    <StageActionButtons component={<Completeness />} /> :
                                displayCoverage ?
                                    claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                        <Coverage /> :
                                        <StageActionButtons component={<Coverage />} /> :
                                    displayInvoice ?
                                        claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                            <Invoice /> :
                                            <StageActionButtons component={<Invoice />} /> :
                                        displayPhoto ?
                                            claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                                <Photo data={photo} photoPreview={photoPreview} /> :
                                                <StageActionButtons component={<Photo data={photo} photoPreview={photoPreview} />} /> :
                                            displayAttachment ?
                                                claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                                    <Attachment data={attachment} attachmentPreview={attachmentPreview} /> :
                                                    <StageActionButtons component={<Attachment data={attachment} attachmentPreview={attachmentPreview} />} /> :
                                                displayMessage ?
                                                    claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                                        <Message /> :
                                                        <StageActionButtons component={<Message />} /> :
                                                        displayFnol ?
                                                        claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                                            <FnolAttachment data={attachment} attachmentPreview={attachmentPreview} /> :
                                                            <StageActionButtons component={<FnolAttachment data={attachment} attachmentPreview={attachmentPreview} />} /> :
                                                            displayPoliceReport ?
                                                            claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED" ?
                                                                <PoliceReportAttachment data={attachment} attachmentPreview={attachmentPreview} /> :
                                                                <StageActionButtons component={<PoliceReportAttachment data={attachment} attachmentPreview={attachmentPreview} />} /> :
                                                                <NoView />
                }
            </Box>
            <Grid container>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    onClick={fetchPdf}
                    disableElevation
                    variant="contained"
                    size="large"
                    fullWidth={belowsm ? true : false}
                    startIcon={<PictureAsPdfOutlinedIcon />}
                    disabled={claim?.flags?.claim === false || claim?.flags?.personalInjury}
                    sx={{
                        mt: 2,
                        mb: belowsm ? 0 : 2,
                        mr: belowsm ? 0 : 1,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>{t("pdfReport")}
                </Button>
                <Button
                    onClick={downloadAllSource}
                    disableElevation
                    variant="contained"
                    size="large"
                    fullWidth={belowsm ? true : false}
                    startIcon={<DownloadForOfflineOutlinedIcon />}
                    sx={{
                        display: displaySource ? "inline-flex" : "none",
                        mt: belowsm ? 1 : 2,
                        mb: belowsm ? 0 : 2,
                        mr: belowsm ? 0 : 1,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>{t("originalDownload")}
                </Button>
                <Button
                    onClick={closeView}
                    disableElevation
                    variant="contained"
                    size="large"
                    fullWidth={belowsm ? true : false}
                    startIcon={<ArrowCircleLeftOutlinedIcon />}
                    sx={{
                        mt: belowsm ? 1 : 2,
                        mb: 2,
                        textTransform: "none",
                        borderRadius: "8px",
                        bgcolor: theme.palette.widget.main,
                        "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                    }}>{t("back")}
                </Button>
            </Grid>
        </Fragment>
    );

}), (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default ViewHandler;