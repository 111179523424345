import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Collapse, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TriangleIcon from '../../../icons/Triangle';
import WidgetTextField from "../WidgetTextField";

const CoverageConditionWidget = ({ obj }) => {

    const { t } = useTranslation();

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);
    const [condition, setCondition] = useState({});

    useEffect(() => {
        const satisfied = Object.values(obj?.data ?? {}).find(c => c.internal === "satisfied")?.api;
        const satisfiedReason = Object.values(obj?.data ?? {}).find(c => c.internal === "satisfiedReason")?.api
        setCondition({
            "condition": satisfied,
            "reason": satisfiedReason
        })
    }, [obj.data])

    const handleCollapse = () => setCollapse(!collapse);

    //eslint-disable-next-line
    const Success = () => {
        return (
            <Avatar sx={{ height: 20, width: 20, bgcolor: "success.main", ml: .5 }}>
                <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />
            </Avatar >
        );
    };

    //eslint-disable-next-line
    const Undetermined = () => {
        return (
            <Avatar sx={{ height: 20, width: 20, bgcolor: "info.main", ml: .5 }}>
                <QuestionMarkIcon sx={{ color: "#fff", fontSize: "14px" }} />
            </Avatar >
        );
    };

    //eslint-disable-next-line
    const Error = () => {
        return (
            <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", ml: .5 }}>
                <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
            </Avatar>
        );
    };

    return (
        <Grid item xs={12} sx={{ display: "block" }}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11} sx={{ display: "flex", flexDirection: "row", pr: 2 }}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: belowsm ? .5 : 1
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                        <Box sx={{ mt: 1, mr: 1 }}>
                            {/* {condition?.condition === "SATISTFIED" ? <Success /> : condition?.condition === "UNDETERMINED" ? <Undetermined /> : condition?.condition === "NOT_SATISFIED" ? <Error /> : null} */}
                        </Box>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Box>
                    <WidgetTextField
                            color="widget"
                            label={t("source")}
                            value={Object.values(obj?.data ?? {}).find(c => c.internal === "source")?.api ?? t("missingSource")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            maxRows={belowsm ? null : 3}
                            InputLabelProps={{
                                sx: { px: 1 }
                            }}
                            InputProps={{
                                readOnly: true,
                                sx: {
                                    pl: 1,
                                    pr: belowsm ? 2 : 5,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                        <WidgetTextField
                            color="widget"
                            label={condition?.condition === "SATISTFIED" ? t("satisfied") : condition?.condition === "UNDETERMINED" ? t("undetermined") : condition?.condition === "NOT_SATISFIED" ? t("notSatisfied") : t("missingConditionSatisfaction")}
                            value={condition?.reason ?? t("missingConditionSatisfaction")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            maxRows={belowsm ? null : 3}
                            InputLabelProps={{
                                sx: { px: 1 }
                            }}
                            InputProps={{
                                readOnly: true,
                                // endAdornment: condition?.condition === "SATISTFIED" ? <Success /> : condition?.condition === "UNDETERMINED" ? <Undetermined /> : null,
                                sx: {
                                    pl: 1,
                                    pr: belowsm ? 2 : 4,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                        <WidgetTextField
                            color="widget"
                            label={t("coverageDescription")}
                            value={Object.values(obj?.data ?? {}).find(c => c.internal === "description")?.api ?? t("missingDescription")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            maxRows={belowsm ? null : 3}
                            InputLabelProps={{
                                sx: { px: 1 }
                            }}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                sx: {
                                    pl: 1,
                                    pr: belowsm ? 2 : 5,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                </Collapse>
            </Paper>
        </Grid>
    )
};

export default CoverageConditionWidget;