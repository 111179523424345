import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/useAxios";

const DeleteDialog = forwardRef(({ setClaims, setRefetch, setLoading }, ref) => {

    const theme = useTheme();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);

    const axiosInstance = useAxios();

    useImperativeHandle(ref, () => ({
        deleteSelected(selected) {
            setOpen(true);
            setSelected(selected);
        },
        deleteAll() {
            setOpen(true);
            setSelected([]);
        }
    }));

    const deleteClaims = async () => {
        setLoading(true);

        for (let i = 0; i < selected.length; i++) {
            await axiosInstance.current({
                url: `/claims/${selected[i]}`,
                method: "DELETE"
            }).then(res => {
                if (res.data && selected[i] === localStorage.getItem("claim")) {
                    localStorage.removeItem("claim");
                };
            }).catch(() => err => {
                console.error(err);
            });
        }
        setRefetch(true);
        setLoading(false);
        setOpen(false);
    };

    const deleteAllClaims = async () => {
        setLoading(true);
        await axiosInstance.current({
            url: "claims/delete-by-customer",
            method: "DELETE"
        }).then(res => {
            if (res.data) {
                setClaims([]);
                localStorage.removeItem("claim");
            };
        }).catch(() => err => {
            console.error(err);
        });
        setLoading(false);
        setOpen(false);
    };

    const getText = () => {
        if (selected?.length === 0) {
            return t("deleteAllClaims");
        }
        if (selected?.length === 1) {
            return t("deleteOneClaim");
        }
        if (selected?.length >= 2) {
            return t("deleteMultipleClaims", { amount: selected?.length ?? t("unknownMultiple") })
        }
        return "";
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>{selected?.length === 0 || (selected?.length !== 1 && selected?.length !== undefined) ? t("deleteClaims") : t("deleteClaim")}</DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal", textAlign: "center" }}>{getText()}</Typography>
            </DialogContent>
            <DialogActions>
                {selected?.length === 0 && selected?.length !== undefined ?
                    <Button variant="contained" disableElevation onClick={deleteAllClaims} sx={{ textTransform: "none", bgcolor: "error.main", "&:hover": { bgcolor: "error.main" } }}>{t("confirm")}</Button> :
                    <Button variant="contained" disableElevation onClick={deleteClaims} sx={{ textTransform: "none", bgcolor: "error.main", "&:hover": { bgcolor: "error.main" } }}>{t("confirm")}</Button>
                }
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>{t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );

});

export default DeleteDialog;