import { Backdrop, Box, CircularProgress, Collapse, Container, Grid, IconButton, List, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import { Fragment, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from 'react-oidc-context';
import { useLocation, useNavigate } from "react-router-dom";
import TriangleIcon from '../../icons/Triangle';
import noPreviewDark from '../../images/no-preview-available-dark.png';
import noPreviewLight from '../../images/no-preview-available-light.png';
import { ClaimContext } from '../../utils/ClaimContext';
import SnackBar from "../../utils/SnackBar";
import { useAxios } from "../../utils/useAxios";
import { useInterval } from "../../utils/useInterval";
import ViewHandler from './ViewHandler';

const Complete = memo(() => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const location = useLocation();
    const navigate = useNavigate();

    const { claim, setClaim } = useContext(ClaimContext);

    const { t } = useTranslation();

    const query = new URLSearchParams(location.search);
    const claimUUIDfromURL = query.get('claim');
    const claimUUIDfromStorage = localStorage.getItem("claim");
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [multiplePhotos, setMultiplePhotos] = useState(false);
    const [failedAttachments, setFailedAttachments] = useState([]);
    const [openListClaim, setOpenListClaim] = useState(belowsm ? false : true);
    const [openListAttachments, setOpenListAttachments] = useState(belowsm ? false : true);

    const viewHandlerRef = useRef(null);
    const attempted = useRef(false);
    const preview = useRef([]);
    const noPreviewDarkURL = useRef(null);
    const noPreviewLightURL = useRef(null);
    const stage = useRef(null);
    const SnackBarRef = useRef(null);

    const error = (!claimUUIDfromURL && !claimUUIDfromStorage) || failed;

    const axiosInstance = useAxios();

    //use memoization for claim stages processing
    const claimMemo = useMemo(() => claim, [claim]);

    useEffect(() => {
        document.title = t("completeTitle");
        if (!claimMemo?.claimUUID && !attempted.current && (claimUUIDfromURL || claimUUIDfromStorage)) {
            fetchClaim();
        }
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        } else {
            setOpenListClaim(true);
            setOpenListAttachments(true);
        }
        //eslint-disable-next-line
    }, [claimMemo, belowsm])

    useInterval(() => {
        if (stage.current === "COMPLETED" || stage.current === "FAILED" || (!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        } else {
            fetchClaim();
        }
    }, 3000);

    const createObjectUrl = (image, dark) => {
        fetch(image)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                if (dark) {
                    noPreviewDarkURL.current = url;
                } else {
                    noPreviewLightURL.current = url;
                }
            })
            .catch(err => console.error(err));
    };

    const fetchFallback = async () => {
        await createObjectUrl(noPreviewDark, true);
        await createObjectUrl(noPreviewLight, false);
    };

    const fetchClaim = async () => {
        if (!attempted.current) {
            setLoading(true);
        }
        await axiosInstance.current({
            url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            const newClaim = res?.data;
            if (!isEqual(claim, newClaim)) {
                setClaim(newClaim ?? {});
                if (newClaim?.claimUUID) {
                    if (newClaim?.attachments?.length > 0) {
                        let count = 0;
                        for (let i = 0; i < newClaim?.attachments?.length; i++) {
                            const attachment = newClaim?.attachments[i];
                            if (attachment?.documentType === "Foto des Schadens") {
                                count += 1;
                                if (count >= 2) {
                                    setMultiplePhotos(true);
                                };
                            };
                        };
                    };
                    fetchAllAttachments(newClaim);
                    if (preview.current?.length === 0) {
                        fetchFallback();
                    }
                    setFailed(false);
                };
            };
            if (res?.data?.currentStage === "COMPLETED" || res?.data?.currentStage === "FAILED") {
                if (stage.current !== null) {
                    if (res.data?.currentStage === "COMPLETED") {
                        SnackBarRef.current.completed(res?.data?.currentStage);
                    } else {
                        SnackBarRef.current.failed(res?.data?.currentStage);
                    }
                };
            } else {
                SnackBarRef.current.stage(res?.data?.currentStage);
            };
            stage.current = res?.data?.currentStage;
        }).catch(() => {
            if (belowsm) {
                navigate("/claims");
            }
            setFailed(true);
        });
        setLoading(false);
        attempted.current = true;
    };

    const fetchAllAttachments = async claim => {
        for (let i = 0; i < claim?.attachments?.length; i++) {
            await fetchPreview(claim?.attachments[i]);
        };
    };

    const fetchPreview = async (attachment) => {
        setLoading(true);
        for (let i = 0; i < attachment?.src?.length; i++) {
            const current = attachment?.src[i] ?? undefined;
            if (preview.current.filter(p => p.id === current)?.length === 0) {
                await axiosInstance.current({
                    url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}/${current}`,
                    method: "GET",
                    responseType: "blob"
                }).then((res) => {
                    const previewObj = {
                        id: current,
                        data: URL.createObjectURL(res.data)
                    }
                    preview.current.push(previewObj);
                }).catch(() => setFailedAttachments([...failedAttachments, attachment?.attachmentUUID]));
            };
        };
        setLoading(false);
    };

    const handleListClaim = () => setOpenListClaim(!openListClaim);
    const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

    const getMatchingPhotos = () => {
        let matching = [];
        let blobOnly = [];
        for (let o = 0; o < claim?.attachments?.length; o++) {
            if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                for (let i = 0; i < preview.current?.length; i++) {
                    if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                        const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                        if (alreadyAdded?.length === 0) {
                            matching.push(preview.current[i]);
                        };
                    };
                };
            };
        };
        if (matching?.length === 0) {
            const fallback = {
                id: "fallback-preview",
                data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
            };
            for (let i = 0; i < claim?.attachments?.length; i++) {
                if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(fallback);
                };
            };
        }
        for (let i = 0; i < matching?.length; i++) {
            blobOnly.push(matching[i].data);
        };
        return blobOnly;
    };

    const getContent = (attachments) => {
        if (attachments) {
            let matching = [];
            for (let i = 0; i < attachments?.length; i++) {
                if (attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(attachments[i]);
                }
            };
            return matching;
        };
        return [];
    }

    const openOverview = (input) => {
        viewHandlerRef.current.openOverview(input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openCompleteness = (input) => {
        viewHandlerRef.current.openCompleteness(input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openCoverage = (input) => {
        viewHandlerRef.current.openCoverage(input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openInvoice = (input) => {
        viewHandlerRef.current.openInvoice(input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openMessage = (input) => {
        viewHandlerRef.current.handleMessage(input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const openPhoto = (input) => {
        let sourceArray = [];
        if (claim?.attachments?.length > 0) {
            for (let i = 0; i < claim?.attachments?.length; i++) {
                if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                    const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.attachments[i]?.attachmentUUID}`;
                    const name = claim?.attachments[i]?.filename;
                    const type = claim?.attachments[i]?.contentType;
                    const sourceObj = {
                        "path": sourcePath,
                        "filename": name,
                        "contentType": type
                    };
                    sourceArray.push(sourceObj);
                };
            };
        };
        viewHandlerRef.current.handlePhoto(getContent(claim?.attachments), getMatchingPhotos(), sourceArray, input);
        if (belowsm) {
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };
    };

    const AttachementListItem = ({ displayName, attachment }) => {

        const getMatching = () => {
            let matching = [];
            for (let i = 0; i < preview.current?.length; i++) {
                if ((preview.current[i]?.id === attachment?.attachmentUUID || (attachment.src && attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0)) && preview.current[i]?.data) {
                    matching.push(preview.current[i].data);
                };
            };
            if (matching?.length === 0) {
                matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
            };
            return matching;
        };

        const openAttachment = (input) => {
            if (belowsm) {
                setOpenListClaim(false);
                setOpenListAttachments(false);
            }
            let sourceArray = [];
            const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
            const sourceObj = {
                "path": sourcePath,
                "filename": attachment?.filename,
                "contentType": attachment?.contentType
            };
            sourceArray.push(sourceObj);
            viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray, input);
        };

        if (attachment?.documentType === "Foto des Schadens") {
            return;
        }

        return (
            <ListItemButton onClick={(event) => { openAttachment(event) }} disabled={error} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{displayName}</Typography>} />
            </ListItemButton>
        )
    };

    if (belowsm) {
        return (
            <Fragment>
                <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", visibility: loading && !claim?.claimUUID ? "hidden" : "visible" }}>
                    <Paper
                        elevation={2}
                        sx={{
                            mt: 2,
                            width: "100%",
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding>
                            <ListItemButton onClick={handleListClaim} sx={{ p: 0, px: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("announcement")}</Typography>} />
                                <IconButton sx={{ transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                    <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                                </IconButton>
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} disabled={error} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("overview")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null || error} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("completeness")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null || error} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("coverage")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null || error} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")}</Typography>} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ p: 0, px: 1, borderBottom: openListAttachments ? 1 : 0, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")}</Typography>} />
                                <IconButton sx={{ transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                    <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                                </IconButton>
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openMessage} disabled={error} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("announcement")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openPhoto} disabled={error} sx={{ py: .25, pl: 4, borderRadius: "6px", display: getMatchingPhotos()?.length > 0 ? "flex" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{multiplePhotos ? t("claimPhotos") : t("claimPhoto")}</Typography>} />
                                    </ListItemButton>
                                    {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                    <ViewHandler ref={viewHandlerRef} />
                </Grid>
                <SnackBar ref={SnackBarRef} />
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", visibility: loading && !claim?.claimUUID ? "hidden" : "visible" }}>
                <Grid item xs={2} sx={{ height: belowmd && !belowsm ? "calc(100vh - 218px)" : "calc(100vh - 160px)", mt: 2, pb: 1 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                            <ListItemButton onClick={handleListClaim} sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{t("announcement")}</Typography>} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("overview")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("completeness")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{t("coverage")}</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? t("invoices") : t("invoice")}</Typography>} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ py: 0, borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{claimMemo?.attachments?.length > 0 ? t("attachmentsText") : t("attachmentText")}</Typography>} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color="inherit">{t("announcement")}</Typography>} />
                                </ListItemButton>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "4px", display: getMatchingPhotos()?.length > 0 ? "flex" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{getMatchingPhotos()?.length > 1 ? t("claimPhotos") : t("claimPhoto")}</Typography>} />
                                    </ListItemButton>
                                    {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={10} sx={{ height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)", pl: 2, pt: 2 }}>
                    {error ?
                        <Container sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography color={theme.palette.text.primary}>{failed ? t("failedView") : t("unavailableView")}</Typography>
                        </Container>
                        :
                        <ViewHandler ref={viewHandlerRef} />
                    }
                </Grid>
            </Grid>
            <SnackBar ref={SnackBarRef} />
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
// Complete.whyDidYouRender = true
export default withAuthenticationRequired(Complete, {
    OnRedirecting: () => {
        const redirect = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        localStorage.setItem("redirect", redirect);
    }
});