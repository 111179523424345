import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { useTranslation } from 'react-i18next';
import WidgetTextField from '../WidgetTextField';

const Attachment = memo(({ data, attachmentPreview }) => {

    const attachment = data;
    const preview = attachmentPreview ?? [];

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const below818 = useMediaQuery('(max-width:818px)')

    const { t } = useTranslation();

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = () => {

        return (
            <Grid container sx={{ display: preview?.length > 0 ? "flex" : "none", justifyContent: below818 ? "center" : "flex-start" }}>
                {preview?.map((p, i) => {
                    return <Grid item key={i} sx={{ maxWidth: belowsm ? "100%" : "300px", m: .5, border: belowsm ? 0 : 1, borderTop: belowsm && i === 0 ? 0 : 1, borderRadius: belowsm ? "0px" : "4px", borderColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                        <img alt={`preview-${i}`} src={p} style={{ borderRadius: belowsm ? "0px" : "4px", width: "100%" }} />
                    </Grid>
                })}
            </Grid>
        );
    };

    const Success = () => {
        return (
            < Avatar sx={{ height: 20, width: 20, bgcolor: "success.main", mr: 1 }}>
                <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />
            </Avatar >
        );
    };

    const Error = () => {
        return (
            <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", mr: 1 }}>
                <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
            </Avatar>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 237px)" : "auto", overflow: "auto" }}>
            <Grid item xs={12} sx={{ pb: 1.5 }}>
                <Paper
                    elevation={2}
                    sx={{
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {attachment?.filename}
                    </Typography>
                    <Box sx={{ p: .5 }}>
                        <Preview />
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{ pb: 0.5 }}>
                <Paper
                    elevation={2}
                    sx={{
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {t("documentContent")}
                    </Typography>
                    <Box sx={{ pt: 1 }}>
                        <WidgetTextField
                            color="widget"
                            value={attachment?.related ? t("attachmentMatches") : t("attachmentDoesNotMatch")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                startAdornment: attachment?.related ? <Success /> : <Error />,
                                sx: {
                                    px: 1,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                        />
                        <WidgetTextField
                            color="widget"
                            label={t("description")}
                            value={checkInput(attachment?.description)}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            InputLabelProps={{
                                sx: { px: 1 }
                            }}
                            InputProps={{
                                readOnly: true,
                                sx: {
                                    px: 1,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                                    borderTopRightRadius: "12px",
                                    borderTopLeftRadius: "12px"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                        <WidgetTextField
                            color="widget"
                            label={t("explanation")}
                            value={checkInput(attachment?.relatedReasoning)}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            InputLabelProps={{
                                sx: { px: 1 }
                            }}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                sx: {
                                    px: 1,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                                    borderTopRightRadius: "12px",
                                    borderTopLeftRadius: "12px"
                                }
                            }}
                            sx={{ mt: 1 }}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
});

export default Attachment;